@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body,
html {
  height: auto !important;
  min-height: 100vh;
}


@layer base {
  :root {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
    --radius: 0.3rem;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.accordion-group-nav [data-strapi-expanded="false"],
.accordion-group-nav [data-strapi-expanded="true"] {
  border: none !important;
  border-bottom: 1px solid #454545 !important;
}

.accordion-group-nav [data-strapi-expanded="false"] > div,
.accordion-group-nav [data-strapi-expanded="true"] > div {
  background: none !important;
}

.accordion-group-nav [data-strapi-dropdown="false"],
.accordion-group-nav [data-strapi-dropdown="true"] {
  background: none !important;
}

.btn.btn-primary {
  background: rgb(73, 69, 255);
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid rgb(73, 69, 255);
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: relative;
  outline: none;
  height: 2rem;
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.btn.btn-primary:hover {
  border: 1px solid rgb(123, 121, 255);
  background: rgb(123, 121, 255);
}

.no-icon svg {
  opacity: 0 !important;
}

.flex-justify-center {
  min-height: 80vh;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  padding: 60px 0;
}

.icon-tooltip {
  margin-left: 4px;
  cursor: help;
}

.icon-tooltip path {
  fill: #fff;
}

.terms-and-conditions {
  font-size: 14px;
  line-height: 1.5;
}

.terms-and-conditions p {
  margin-bottom: 1rem;
}

.terms-and-conditions strong {
  font-weight: bold;
}

.terms-and-conditions a {
  color: #4a90e2;
}

.terms-and-conditions em {
  font-style: italic;
}
.max-w-input label {
  display: block;
  margin-bottom: 10px;
}
.max-w-input label + div {
  max-width: 100px;
}

.estimator-phone-number label[for="phoneNumber"] {
  display: none;
}

.hidden-input {
  position: absolute !important;
  left: -9999px;
  top: -9999px;
  z-index: -9999;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  cursor: pointer;
}

.list-action-tc {
  display: flex;
  align-items: flex-end;
  width: 300px;
  max-width: 100%;
}

.filter-claims {
  display: flex;
}

.filter-claims form {
  width: calc(100% - 340px);
}

.filter-claims > button {
  text-transform: uppercase;
  height: 42px !important;
  min-width: 110px;
  width: auto;
  text-align: center !important;
  justify-content: center;
  margin-left: 10px;
  padding: 8px 4px !important;
}

.filter-claims > button.small {
  min-width: 80px;
  background-color: #fff;
  color: #222;
  border-color: #fff;
}

.filter-claims > button.small:hover {
  background-color: #2f7d31;
  color: #fff;
  border-color: #2f7d31;
}
.filter-claims > button.disabled {
  pointer-events: none;
  background-color: #959595 !important;
  border-color: #959595 !important;
}

.filter-claims > button.active {
  background-color: #2f7d31 !important;
  border-color: #2f7d31 !important;
  color: #fff;
}

.trueclaim-col-actions > .tc-actions-item:not(.chat) {
  cursor: default !important;
}

.ReactModal__Overlay--after-open {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content--after-open {
  width: calc(100% - 20px);
  padding: 0 !important;
  max-width: 1024px;
}

.ReactModal__Content--after-open > button {
  position: absolute;
  right: 0;
  top: 0;
  background: #222;
  width: 40px;
  height: 40px;
  border-radius: 0 0 0 4px;
  font-size: 0;
  z-index: 2;
}

.ReactModal__Content--after-open > button::after {
  content: "x";
  font-size: 24px;
  line-height: 0;
  margin-top: 5px;
}

.ReactModal__Content--after-open > div {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.ReactModal__Content--after-open iframe {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1023px){
  .ReactModal__Content--after-open {
    max-width: 768px;
  }

  .ReactModal__Content--after-open iframe {
    height: 560px;
  }
}
@media (max-width: 767px){
  .ReactModal__Content--after-open {
    max-width: 400px;
  }

  .ReactModal__Content--after-open iframe {
    height: 320px;
  }
}

@media (min-width: 992px){
  .btn-refresh {
    display: none !important;
  }

  .mobile-v,
  .mobile-nav:not(.nav-left) {
    display: none !important;
  }

  .date-width {
    display: block;
    max-width: 150px;
    white-space: normal;
  }

  .tc-actions-item.view {
    display: none;
  }

  .mobile-nav.nav-left {
    padding: 20px 15px;
  }

  .mobile-nav.nav-left img {
    width: 100%;
    height: auto;
  }

  .mobile-nav.nav-left .mobile-nav__burger {
    display: none !important;
  }
}

.colum-action {
  width: 1%;
}

.base-header-layout > div {
  padding-top: 20px;
}
@media (max-width: 991px){
  .filter-claims {
    position: relative;
  }

  .hidden-mb,
  .desktop-v {
    display: none !important;
  }

  .base-header-layout input[name="searchuser"],
  .base-header-layout input[name="searchbar"] {
    font-size: 16px;
  }
  
  .main-navigation {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    margin-left: -100%;
    transition: all 300ms;
    width: 100%;
  }

  .mobile-nav + div {
    display: block !important;
  }

  .main-navigation.active {
    margin-left: 0;
  }

  .main-navigation nav {
    position: relative;
    width: 100%;
  }

  .mobile-nav {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #595959;
  }

  .mobile-nav img {
    max-width: 200px;
    height: auto;
  }

  .mobile-nav__burger svg {
    width: 40px;
    height: 40px;
    border: 1px solid #595959;
    border-radius: 4px;
  }

  .mobile-nav__burger svg path {
    fill: white;
  }

  .mobile-nav.nav-left .mobile-nav__burger {
    width: 40px;
    height: 40px;
    border: 1px solid #595959;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-nav.nav-left .mobile-nav__burger svg {
    width: 16px;
    height: 16px;
    border: none;
  }

  .base-header-layout > div {
    padding: 10px 15px;
  }

  a.claim-view-detail {
    max-width: 100%;
    display: block;
  }

  .bottom-toolbar {
    justify-content: center !important;
  }

  .bottom-toolbar .limiter {
    display: none;
  }

  .mb-fw-ct {
    display: block !important;
  }

  .mb-fw {
    width: 100% !important;
  }

  .filter-claims {
    flex-wrap: wrap;
  }
  
  .filter-claims form {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .filter-claims button {
    width: calc(50% - 5px);
    margin-left: 0;
    padding: 8px 10px;
    margin-bottom: 10px;
  }

  .filter-claims button + button {
    margin-left: 10px;
  }

  .list-action-tc {
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .list-action-tc .tc-cta {
    display: flex;
    order: 1;
    width: 100%;
    max-width: 300px;
    justify-content: space-between;
    padding-top: 5px;
  }

  .main-navigation nav ul {
    display: block;
  }

  .main-navigation nav ul li a {
    margin-top: 10px;
    display: block;
  }

  .main-navigation nav ul li a[href="/dashboard"],
  .main-navigation nav ul li a[href="/settings"],
  .main-navigation nav ul li a[target="_blank"] {
    display: none;
  }
}

@media (max-width: 767px){
  .hidden-mobile {
    display: none !important;
  }
}

.filter-claims + div form > div,
.filter-claims form > div {
  border: 1px solid #959595; 
}

.custom-datepicker {
  border-radius: 4px;
  padding: 6px 10px 5px 40px !important;
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 0.875rem;
  display: block;
  width: calc(100% - 10px);
  background: inherit;
  height: 42px;
  border: 1px solid #959595; 
  margin-left: 10px;
}

.react-datepicker-wrapper {
  width: 340px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  fill: white;      
  top: 5px;
  left: 12px;
}

.video-list-container > div {
  grid-template-columns: repeat(10, 1fr) !important;
}

.video-container {
  position: relative;
  cursor: pointer;
}

.video-container .video-iframe {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s;
}

.video-container .video-iframe:hover {
  border-radius: 0;
}

.video-container .video-iframe img {
  width: 100%;
  height: auto;
}

.video-title {
  padding: 12px;
}

.driver-google {
  margin-top: 100px;
  border-radius: 12px;
  overflow: hidden;
}

.main-navigation li {
  position: relative;
}
a.logout {
  border: 1px solid #959595;
  padding: 4px 10px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
}
a.logout svg {
  width: 12px;
}
a.logout path {
  fill: #fff
}

.main-navigation li .clipboard-icon {
  position: absolute;
  right: 0;
  top: 3px;
  border: 1px solid #959595;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main-navigation li .clipboard-icon path {
  fill: #959595;
}

.clipboard-icon svg + span {
  display: none;
}

.flag-actions {
  background: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 5px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag-actions-adas svg path {
  fill: #fff;
}

.top-actions .flag-actions {
  margin-left: 10px;
}

.driver-google iframe {
  width: 100%;
  height: auto;
  background-color: #fff;
  min-height: 400px;
}
@media (max-width: 1600px){
  .filter-claims {
    flex-wrap: wrap;
  }

  .react-datepicker-wrapper + button {
    margin-left: 0;
  }

  .filter-claims > button {
    margin-top: 10px;
  }

  .filter-claims > .btn-filter {
    width: calc((100% - 370px) / 2);
  }
}
@media (max-width: 1600px) and (min-width: 1200px){
  .filter-claims > button.small {
    width: calc(100% / 6 - 10px) !important;
  }
  .filter-claims > .btn-filter {
    width: calc(100% / 6 - 5px) !important;
  }
}
@media (max-width: 991px){
  .filter-claims > button {
    margin-top: 0;
  }
  .react-datepicker__view-calendar-icon input {
    margin-left: 0;
  }
  .react-datepicker-wrapper {
    width: 360px;
  }

  .filter-claims > button.small {
    min-width: calc((100% - 30px) / 4) !important;
    width: calc((100% - 30px) / 4) !important;
    margin-left: 0 !important;
  }

  .filter-claims > button.small + button.small {
    margin-left: 10px !important;
  }
}

.button-new-claim {
  margin-left: auto;
  margin-right: 10px;
}
@media (max-width: 767px){
  .mobile-grid-12 > div {
    grid-column: span 12;
  }

  .mobile-header-layout > div > div {
    display: block !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
  .react-datepicker__view-calendar-icon input {
    width: 100%;
  }

  .filter-claims > .btn-filter {
    width: calc((100% - 10px) / 2);
  }
}

.custom-form-delete-account label {
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-top: 20px;
}

.custom-form-delete-account textarea {
  background-color: transparent;
  border: 1px solid rgb(74, 74, 106);
}

.white-chat-bar .MuiChartsAxis-tickLabel {
  fill: white !important;
}

.white-chat-bar .MuiChartsAxis-tick {
  stroke: white !important;
}

.white-chat-bar .MuiChartsAxis-line {
  stroke: white !important;
}

.responsive-chart > div {
  max-width: 100%;
}

/* Claim Detail */
.mobile-tab {
  padding: 15px;
  border: 1px solid #464646;
  margin-top: 10px;
  border-radius: 4px;
}

input[type="text"]#operation_id {
  width: 0;
  height: 0;
  padding: 0;
  border: none;
  position: absolute;
}

.custom-nav-link {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #454545 !important;
}

.menu-right-hoz .group {
  background-color: #3f3f3f;
  border-radius: 5px;
  padding: 10px 5px;
  text-align: center;
  cursor: pointer;
}

.menu-right-hoz .group span {
  display: block;
  line-height: 0.9;
}

.custom-nav-link a {
  min-height: 3rem;
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 0.875rem;
  line-height: 1.43;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: rgb(234, 234, 239);
}

.calibration-selection label {
  color: rgb(17 24 39) !important;
}

.custom-nav-link a.active,
.custom-nav-link a:hover {
  color: rgb(123, 121, 255);
}

.bg-strapi-neutral-800 {
  background-color: rgb(24, 24, 38);
  box-shadow: rgba(3, 3, 5, 0.35) 1px 1px 10px;
}

.mobile-tab.active {
  background-color: rgb(33, 33, 52);
  border-radius: 4px 4px 0 0;
}

.left-container-galery .light-box-container {
  margin: 20px auto 0;
}

.tc-claim-title-container h2 {
  font-size: 1.5rem;
}

.tc-claim-content-container div[role="tablist"] > button {
  outline: none !important;
}

.tc-claim-content-container div[role="tablist"] > button[aria-selected="false"] > div {
  background-color: transparent !important;
  border: none !important;
}

.tc-claim-content-container div[role="tablist"] > button[aria-selected="true"] > div {
  background-color: #1f2937 !important;
  border-color: #1f2937 !important;
}

.tc-claim-content-container .tab-panels-container > div > div {
  background-color: transparent !important;
}

.tc-claim-content-container .tab-panels-container {
  background-color: #1f2937 !important;
}

.table-sticky-header.sticky-header {
  padding-top: 49px;
  position: relative;
  z-index: 1;
}

@media (min-width: 1200px) and (max-width: 1450px){
  .tc-damage-information .tc-dmif-description,
  .tc-damage-information .tc-dmif-trail {
    grid-column: span 4;
    max-width: 100%;
  }
}
@media (max-width: 1199px){
  .tc-report-container {
    flex-wrap: wrap;
  }

  .tc-report-container table tr td,
  .tc-report-container table tr th {
    width: 1%;
    white-space: normal;
  }
}
@media (min-width: 992px) and (max-width: 1199px){
  .tc-damage-information .tc-dmif-svg {
    grid-column: span 12;
    max-width: 100%;
  }

  .svg-box-container svg {
    max-height: 400px;
    margin: 0 auto;
  }
  .tc-damage-information .tc-dmif-description,
  .tc-damage-information .tc-dmif-trail {
    grid-column: span 6;
    max-width: 100%;
  }
  .tc-claim-title-container > div {
    padding: 24px 20px 40px;
  }
  .light-box-thumb {
    width: 20% !important;
  }
  .main-image-zoom {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px){
  
  .light-box-thumb {
    width: 20% !important;
  }
  .tc-damage-information .tc-dmif-svg {
    grid-column: span 12;
    max-width: 100%;
  }

  .svg-box-container svg {
    max-height: 400px;
    margin: 0 auto;
  }
  .tc-damage-information .tc-dmif-description,
  .tc-damage-information .tc-dmif-trail {
    grid-column: span 6;
    max-width: 100%;
  }
  .tc-claim-title-container > div {
    padding: 24px 20px 40px;
  }
  .main-image-zoom {
    display: none !important;
  }
}

@media (min-width: 768px){
  .mobile-tabs {
    display: none;
  }
}

@media (max-width: 767px) {
  .table-report-log th {
    display: none;
  }

  .table-report-log table,
  .table-report-log tbody,
  .table-report-log tr td,
  .table-report-log tr {
    display: block;
    max-width: 100%;
    width: 100%;
    min-width: 0;
    padding: 0;
  }

  .table-report-log tr td + td {
    margin-top: 15px;
  }

  .table-report-log tr td img {
    max-width: 100%;
    height: auto;
  }
  .main-image-zoom {
    display: none !important;
  }
  .tab-panels-container > div[role="tabpanel"] > div {
    padding: 10px;
  }

  .trail-list {
    max-height: 1000000px !important;
    min-height: 0;
  }

  .tc-claim-title-container > div {
    padding: 24px 20px 40px;
  }
  .tc-claim-title-container h2 {
    font-size: 1.5rem;
  }

  .tc-claim-title-container > div > div + div {
    display: block;
    margin-bottom: 10px;
  }

  .tc-claim-content-container div[role="tablist"] {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0;
    visibility: hidden;
  }

  .tc-damage-information > div {
    grid-column: span 12;
    max-width: 100%;
  }

  .main-img-container .left-cl-galery,
  .main-img-container .right-cl-galery {
    grid-column: span 12;
  }
  .main-img-container .left-container-galery {
    flex-wrap: wrap;
  }

  .filter-image {
    margin-bottom: 20px;
  }

  .filter-image li {
    width: auto !important;
    margin: 0 20px 10px 0 !important;
  }
}
/* End Claim Detail */
span.text-wrap.text-sm.text-center.block {
  background: #6d6d6d;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 4px;
  max-width: 300px;
  margin-top: 4px;
}

span.text-wrap.text-sm.text-center.block.ai-estimate {
  background: #2f7d31;
}

span.text-wrap.text-sm.text-center.block.dispatched-ai {
  background: #0a5fb9;
}

span.text-wrap.text-sm.text-center.block.human-estimate {
  background: #0aaeb9;
}

.images-opline {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}

.images-opline .images-opline-item {
  width: 150px;
  max-width: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.images-opline .images-opline-item .sqrimg {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 4px;
}

.images-opline .images-opline-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 10px);
  max-height: calc(100% - 10px);
}

@media (max-width: 1199px){
  .video-list-container > div {
    grid-template-columns: repeat(12, 1fr) !important;
  }
  .video-list-container > div > div {
    grid-column: span 4;
  }
}

@media (max-width: 991px){
  body {
    background: rgb(24, 24, 38);
  }
  .video-list-container > div {
    grid-template-columns: repeat(12, 1fr) !important;
  }
  .video-list-container > div > div {
    grid-column: span 6;
  }
}

@media (max-width: 768px){
  .video-list-container > div {
    grid-template-columns: repeat(12, 1fr) !important;
  }
  .video-list-container > div > div {
    grid-column: span 12;
  }
}

table td.py-1 {
  padding-top: 10px;
  padding-bottom: 10px
}

.main-navigation + div {
  padding-bottom: 0;
}

.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
  background-color: transparent !important;
  color: #fff !important;
  border: none !important;
}

.react-international-phone-input-container {
  border: 1px solid rgb(74, 74, 106);
  border-radius: 4px;
  background: rgb(33, 33, 52);
  outline: none;
  transition-property: border-color, box-shadow, fill;
  transition-duration: 0.2s;
}

.react-international-phone-input-container:focus-within {
  border: 1px solid rgb(123, 121, 255);
  box-shadow: rgb(123, 121, 255) 0px 0px 0px 2px;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
  background-color: transparent !important;
  border-width: 0 1px 0 0 !important;
  border-color: rgb(74, 74, 106) !important;
  padding: 0 10px;
}

.tooltip-table > span,
.list-vin-number > span {
  display: flex;
}

.fullscreen-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.slider-controls {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
  z-index: 1001;
}

.slider-controls button {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}
button.custom-padding {
  padding: 8px 5px !important;
}

button.custom-padding span {
  font-weight: 400 !important;
  font-size: 13px;
}

body.lock-body-scroll #sticky-child,
body.lock-body-scroll nav.fixed.border-solid {
  z-index: 1;
}

.adas-system-container > div {
  border: none !important;
}
.adas-system-container > div > div {
  background-color: rgb(24, 24, 38) !important;
  padding: 1.5rem;
}
.adas-system-container > div[data-strapi-expanded="true"] > div {
  padding-bottom: 0;
}

.bg-trueclaim {
  background-color: rgb(123, 121, 255);
}
.adas-system-container > div > div > div > button > span > span {
  font-size: 1.5rem;
    line-height: 2rem;
}
.slider-controls button:hover {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

.slider-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image img {
  max-width: 90%;
  height: auto;
  max-height: 90dvh;
}

.list-vin-number .vin-number {
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
}

.max-90 {
  width: 90px;
  max-width: 90px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
}

.max-50 {
  width: 52px;
  max-width: 90px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
}

.max-150 {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
}

.list-view-detail {
  font-weight: 700;
  text-decoration: underline;
  color: rgb(123, 121, 255);
}

.list-view-detail span {
  text-decoration: underline;
  text-transform: uppercase;
}

.tooltip-table {
  display: inline-block;
}

td.p-0 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 14px !important;
}

th.w-2 {
  text-align: center;
}

.tc-actions-stt {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 7px;
  display: block;
  margin: 0 auto;
}

.tc-actions-stt.completed,
.tc-actions-stt.owner-msg-completed {
  background-color: #2f7d31;
}

.tc-actions-stt.owner-msg-sent {
  background-color: #dfc720;
}

.tc-actions-stt.owner-msg-sent-but-not-complete {
  background-color: #0aaeb9;
}

.tc-actions-stt.failed {
  background-color: #9d0100;
}

.tc-actions-stt.disabled {
  background-color: #a1a1a6;
}