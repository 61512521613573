.st1 {
  font-size: 7px;
}

.st2 {
  fill: #FFFFFF;
  stroke: #000000;
}

svg .hovering .st2 {
  fill: #7bd6ef !important;
}

.st3 {
  fill: #222;
}

.digram-box {
  width: 100%;
  height: auto;
  max-width: 512px;
  margin: 0 auto;
  padding: 20px;
}

.maptext {
  font-family: 'MyriadPro-Regular';
  font-size: 6;
}

#tooltip {
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px;
  display: none;
  position: absolute;
}

#tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.textcl {
  display: none;
}

.text-green-700 circle,
.text-green-700 ellipse,
.text-green-700 path {
  fill: rgb(21,128,61) !important;
}

.text-yellow-200 circle,
.text-yellow-200 ellipse,
.text-yellow-200 path {
  fill: rgb(254,240,138) !important;
}

.text-orange-500 circle,
.text-orange-500 ellipse,
.text-orange-500 path {
  fill: rgb(249,115,22) !important;
}

.text-red-700 circle,
.text-red-700 ellipse,
.text-red-700 path {
  fill: rgb(185,28,28) !important;
}

.text-green-700:hover circle,
.text-green-700:hover ellipse,
.text-green-700:hover path {
  fill: #7bd6ef !important;
}

.text-yellow-200:hover circle,
.text-yellow-200:hover ellipse,
.text-yellow-200:hover path {
  fill: #7bd6ef !important;
}

.text-orange-500:hover circle,
.text-orange-500:hover ellipse,
.text-orange-500:hover path {
  fill: #7bd6ef !important;
}

.text-red-700:hover circle,
.text-red-700:hover ellipse,
.text-red-700:hover path {
  fill: #7bd6ef !important;
}

.light-box-report-container {
  display: flex;
}

.tc-report-container {
  display: flex;
  justify-content: space-between;
}

.tc-report-container svg {
  max-width: calc(100% - 40px) !important;
  margin: 20px auto;
}

.svg-box-container svg {
  max-width: 100%;
}

.yarl__relative {
  max-width: 1200px !important;
}

.yarl__thumbnails_container,
.yarl__container {
  background-color: transparent !important;
}

.yarl__thumbnails_bottom .yarl__thumbnails_vignette, .yarl__thumbnails_top .yarl__thumbnails_vignette {
  background: none !important;
}
.light-box-report-container .light-box-container {
  width: calc(100% - 540px);
  flex: calc(100% - 540px);
}

.tc-poi-pie-item {
  stroke: rgb(238, 236, 236);
  strokeWidth: 1px;
  outline: none;
  cursor: pointer;
  fill: rgb(255, 255, 255);
}

.tc-poi-pie-item:hover {
  fill: rgb(206, 227, 232);
}

.tc-poi-pie-item.selected {
  fill: rgb(245, 226, 116);
}

.tc-poi-pie-item.disabled:not(.selected) {
  fill: rgb(238, 236, 236);
  pointer-events: none;
}

.tc-poi-pie-item.disabled.dfselected {
  fill: rgb(245, 226, 116);
}

.tc-poi-pie-item.disabled.chatselected {
  fill: rgb(245, 226, 116);
}

.tc-poi-pie-item.default {
  position: relative;
}

.tc-poi-pie-item.default:before {
  content: "";
  background: red;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.text-poi.default {
  fill: red;
}

.digram-table tr.active {
  background: #5b5b5b;
}

.digram-box.on-hover .part:not(.active) circle,
.digram-box.on-hover .part:not(.active) ellipse,
.digram-box.on-hover .part:not(.active) path {
  fill: #fff !important;
}

.digram-table tr {
  cursor: pointer;
}

.digram-table.no-hover-effect {
  padding: 20px 10px 0 10px;
}

.digram-table.no-hover-effect * {
  cursor: default !important;
}

.digram-table tr td:not(.no-padding) {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.trueclaim-col-actions {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  padding: 20px 0 0 0;
}

.trueclaim-col-actions:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  height: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.tc-actions-details-container {
  display: flex;
  justify-content: flex-end;
  min-width: 410px;
  position: relative;
}

.tc-actions-details-container .tc-dmif-trail {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 7px 0px #fff;
  margin-top: 10px;
  z-index: 99;
}

.tc-actions-item {
  display: block;
  padding: 4px 0px;
  color: #000;
  border-radius: 4px;
  text-align: center;
  background: #fff;
  border: 1px solid #ddd;
  max-width: 100px;
  font-size: 12px;
  width: calc(100% / 4 - 1px);
}

.tc-actions-item.chat {
  cursor: pointer;
}

.tc-actions-item.chat.owner-msg-completed {
  background-color: #2f7d31;
  color: #fff;
  border-color: #2f7d31;
}

.tc-actions-item.chat.owner-msg-sent {
  background-color: #dfc720;
  color: #fff;
  border-color: #dfc720;
}

.tc-actions-item.chat:hover {
  opacity: 0.8;
}

.tc-actions-details-container .tc-actions-item.chat {
  min-width: 55px;
}

.tc-actions-item.chat.owner-msg-completed:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: calc(25% - 1px);
  background-color: #2f7d31;
  border-radius: 3px 0 0 3px;
}

.tc-actions-item.chat.owner-msg-completed::after {
  content: none;
}

.tc-actions-item.generate-estimate.completed:not(.processing)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  height: 15px;
  width: calc(100% / 4);
  background-color: #2f7d31;
}

.tc-actions-item.generate-estimate.completed::after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #2f7d31;
  position: absolute;
  top: 2px;
  left: 50%;
  transform: rotate(45deg);
  margin-left: -5px;
  z-index: 2;
}

.tc-actions-item.generate-estimate.failed:not(.processing)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  height: 15px;
  width: 25%;
  background-color: #9d0100;
  border-radius: 3px 0 0 3px;
}

.tc-actions-item.generate-estimate.failed::after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #9d0100;
  position: absolute;
  top: 2px;
  left: 50%;
  transform: rotate(45deg);
  margin-left: -5px;
  z-index: 2;
}

.tc-actions-item.apply-allowance.completed:not(.processing)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 15px;
  width: calc(100% / 4 + 2px);
  background-color: #3ca93f;
  border-radius: 3px 0 0 3px;
  margin-left: -2px;
}


.tc-actions-item.apply-allowance.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 15px;
  width: calc(100% / 4 + 2px);
  background-color: #a1a1a6;
  border-radius: 3px 0 0 3px;
  margin-left: -2px;
}

.tc-actions-item.trigger-adas.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  width: 25%;
  background-color: #a1a1a6;
  border-radius: 0 3px 3px 0;
}

.tc-actions-item.generate-estimate.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  height: 15px;
  width: 25%;
  background-color: #a1a1a6;
  border-radius: 3px 0 0 3px;
}

.tc-actions-item.apply-allowance.completed::after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #3ca93f;
  position: absolute;
  top: 2px;
  left: 75%;
  transform: rotate(45deg);
  margin-left: -5px;
  z-index: 1;
}

.tc-actions-item.apply-allowance.failed:not(.processing)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 15px;
  width: calc(100% / 4 + 2px);
  background-color: #ad1f1e;
  border-radius: 3px 0 0 3px;
  margin-left: -2px;
}

.tc-actions-item.apply-allowance.failed::after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #ad1f1e;
  position: absolute;
  top: 2px;
  left: 75%;
  transform: rotate(45deg);
  margin-left: -5px;
  z-index: 1;
}

.tc-actions-item.trigger-adas.completed:not(.processing)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  width: 25%;
  background-color: #4bd34f;
  border-radius: 0 3px 3px 0;
}

.tc-actions-item.trigger-adas.failed:not(.processing)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  width: 25%;
  background-color: #c23838;
  border-radius: 0 3px 3px 0;
}

.tc-actions-item.completed {
  background-color: #2f7d31;
  color: #fff;
  border-color: #2f7d31;
}

.tc-actions-item.failed {
  background-color: #c23838;
  color: #fff;
  border-color: #c23838;
}

.tc-actions-item.generate-estimate.failed {
  background-color: #9d0100;
  color: #fff;
  border-color: #9d0100;
}

.tc-actions-item.apply-allowance.failed {
  background-color: #ad1f1e;
  color: #fff;
  border-color: #ad1f1e;
}

.tc-actions-item.processing {
  background-color: #d7d7d7;
  color: #fff;
  border-color: #d7d7d7;
  box-shadow: none;
  pointer-events: none;
  cursor: no-drop;
}

.tc-actions-item.disabled {
  box-shadow: none;
  pointer-events: none;
  cursor: no-drop;
  opacity: 0.7;
  background-color: #d7d7d7;
  color: #fff;
  border-color: #d7d7d7;
}

.tc-actions-item.disabled.failed {
  background-color: #d3302f;
  color: #fff;
  border-color: #d3302f;
}

.tc-actions-item.disabled.completed {
  background-color: #2f7d31;
  color: #fff;
  border-color: #2f7d31;
}

.tc-actions-item.processing:before {
  content: '';
  padding-top: 1px;
  margin-right: 3px;
  width: 14px;
  height: 14px;
  font-size: 14px;
  text-align: center;
  color: green;
  background-color: white;
  border: 2px solid #EAF0F6;
  border-radius: 50%;
  border-top: 2px solid green;
  animation: spinner 1.5s linear infinite;
  display: inline-block;
  vertical-align: top;
  margin-top: 3px;
}

.image-container {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
}

.image-container.input-image::before {
  position: absolute;
  content: "Input Image";
  top: 10px;
  left: 10px;
  padding: 8px 10px;
  background: #397c34;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  z-index: 2;
}

.image-container.data-image::before {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 8px 10px;
  background: #397c34;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  z-index: 2;
  content: attr(data-title);
  text-transform: capitalize;
}

.image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  z-index: 1;
}

.filter-image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filter-image li {
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  margin-left: 10px;
}

.filter-image li:first-child {
  margin-left: 0;
}

.filter-image li.end-parts {
  margin-right: 0;
  margin-left: auto;
  font-weight: 700;
  text-transform: uppercase;
}

.filter-image li label {
  cursor: pointer;
}

.filter-image li > div {
  margin-right: 3px;
}

.light-box-images-thumb {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0;
  position: relative;
}

.light-box-thumb {
  width: calc(100% / 10);
  margin-bottom: 20px;
  cursor: pointer;
  padding: 0 10px;
}

.light-box-thumb .images-actions {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.light-box-thumb .images-actions button {
  margin: 10px 0;
}
@media (max-width: 768px) {
  .light-box-thumb {
    width: calc(100% / 4);
    padding: 0 5px;
  }
}

.light-box-thumb:not(.active){
  opacity: 0;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

.sqrimg {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 4px;
}

.images-opline .images-opline-item .part-mask-canvas,
.light-box-thumb .part-mask-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.light-box-thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 10px);
  max-height: calc(100% - 10px);
}

.no-padding {
  padding: 0 !important;
}

.tc-report-container .digram-table {
  position: relative;
  width: 100%;
}

/* Chat */
.w-full.h-full.flex.flex-col.relative {
  max-width: 500px;
  background-color: #fff;
  margin: 0 auto;
}

.bg-chat-bot {
  background-color: #f3f4f6;
  color: #222;
}

.view-mitchell {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 50px;
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  position: relative;
}

.view-mitchell span {
  display: flex;
  align-items: center;
}

.view-mitchell span svg {
  margin-left: 4px;
  width: 12px;
}

.view-mitchell span svg path {
  fill: #fff;
}

.view-mitchell.disabled {
  background-color: #d7d7d7;
  pointer-events: none;
}

/* Trail */
.trail-list {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  min-height: 100%;
}

.trail-data-list .trail-data-item {
  position: relative;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.trail-data-list .trail-data-item .trail-action {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #fff;
  color: #222;
  font-size: 12px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  position: relative;
  min-width: 64px;
}

.trail-data-list .trail-data-item  + .trail-data-item .trail-action::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100px;
  background-color: #fff;
  margin-top: -100%;
}

.trail-data-list .trail-data-item .trail-action + div {
  width: calc(100% - 80px);
  margin-left: 20px;
}

.trail-data-list .trail-data-item .trail-time {
  font-size: 12px;
  color: #ddd;
  margin-bottom: 15px;
}

.trail-data-change {
  margin-bottom: 5px;
}

.trail-data-change > span {
  color: #ddd;
}

.green-color {
  color: #2f7d31;
}

.main-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-rp-image-sqrimg .part-mask-canvas,
.main-img-wrapper .part-mask-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-rp-image-sqrimg,
.main-image-sqrimg {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
}

.main-rp-image-sqrimg img,
.main-image-sqrimg img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 800px;
}

.damage-score-filter {
  margin: 0 auto 20px;
}

.damage-score-filter .title_filter {
  margin-bottom: 10px;
  display: block;
}

.damage-score-filter .MuiSlider-markLabel {
  color: #fff;
  font-weight: 600;
}

.damage-score-filter .slider-horizontal {
  width: calc(100% - 20px) !important;
  margin-left: 10px !important;
}

.main-image-zoom {
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
  padding: 4px;
  background-color: rgba(0,0,0,0.7);
}

.main-image-cross {
  display: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: #fff;
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 10000;
}

.main-image-cross svg,
.main-image-zoom svg {
  width: 24px;
  height: 24px;
  transition: all 300ms;
  transform: scale(1);
}

.main-image-cross:hover svg,
.main-image-zoom:hover svg {
  transform: scale(1.1);
}

.main-image-cross svg path,
.main-image-zoom svg path {
  fill: #fff;
}

.main-img-container {
  position: relative;
}

.main-img-container.active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #222134;
  overflow-y: auto;
}

.main-img-container.active .top-cl-galery {
  order: 1;
}

.main-img-container.active .light-box-images-thumb {
  max-height: 100vh;
}

.main-img-container.active .main-image-sqrimg {
  max-width: 100%;
}

.main-img-container.active .main-image-sqrimg img {
  max-height: calc(100vh - 200px);
}

.main-img-container.active .light-box-thumb {
  width: calc(100% / 12);
}

.main-img-container.active .left-cl-galery {
  grid-column: span 8;
}
.main-img-container.active .right-cl-galery {
  grid-column: span 4;
}

.main-img-container.active .main-image-zoom {
  display: none;
}

.main-img-container.active  .filter-image {
  margin-top: 100px;
}

.main-img-container.active .main-image-cross {
  display: block;
}

.loading-center div[role="alert"] {
  display: flex;
  align-items: center;
  justify-content: center;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tc-estimate-table tr td {
  white-space: normal;
  max-width: 200px;
}

div[role="tabpanel"] div[data-strapi-expanded="true"],
div[role="tabpanel"] div[data-strapi-expanded="false"] {
  border: 1px solid rgb(117 117 117);
  margin-bottom: 20px;
}

.form-label-style label {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
}

.form-label-style input {
  color: #222;
}

.tc-diff-bms-table .diff-status {
  text-transform: uppercase;
}

.tc-diff-bms-table tbody tr.matched .diff-status {
  background-color: #9fffa2;
}
.tc-diff-bms-table tbody tr.modified .diff-status {
  background-color: #fffa9f;
}

.tc-diff-bms-table tbody tr.modified .highlight {
  color: #fffa9f;
  font-weight: 700;
}

.tc-diff-bms-table tbody tr td,
.tc-diff-bms-table thead tr th {
  text-align: center;
}

.tc-diff-bms-table thead tr th {
  background-color: #222;
}

.tc-diff-bms-table thead tr th > div {
  width: 100%;
  display: block;
}
.tc-diff-bms-table tbody tr td.thead,
.tc-diff-bms-table thead tr th.thead {
  text-align: left !important;
  background-color: #222;
  width: 250px;
  padding: 0 15px;
}

.acc-nopading > div > div > div {
  padding: 0 !important;
}